import type {
  TestHeaders,
  TestTags,
} from 'src/utils/productionTesting/productionTesting';

import type { Meta } from '../meta';

export const UPDATE_AUTHENTICATED = 'UPDATE_AUTHENTICATED';
export const UPDATE_SERVER_AUTHENTICATED = 'UPDATE_SERVER_AUTHENTICATED';
export const SET_SEEKER_ID = 'SET_SEEKER_ID';
export const UPDATE_TEST_HEADERS = 'UPDATE_TEST_HEADERS';
export const UPDATE_TEST_TAGS = 'UPDATE_TEST_TAGS';

export interface UserState {
  authenticated?: boolean;
  /**
   * Temporarily syncing server side authentication state in the user store.
   * This is only used during the transition to SSA, and it will be removed
   * once we fully switch to SSA.
   *
   * DON'T USE THIS VALUE TO DETERMINE IF THE USER IS AUTHENTICATED IN UI COMPONENTS.
   */
  serverAuthenticated?: boolean;
  userClientId: string;
  /**
   * If you're selecting this value in a React component or a custom React hook, it's
   * advised to use `useCandidateDetails` hook instead.
   * We're only keeping this value here for the sake of compatibility with Redux actions.
   */
  seekerId?: number;
  sessionId: string;
  solId?: string;
  testHeaders: TestHeaders;
  testTags?: TestTags;
}

interface UpdateAuthenticatedAction {
  type: typeof UPDATE_AUTHENTICATED;
  payload: {
    authenticated: boolean;
  };
  meta?: Meta;
}

interface UpdateServerAuthenticatedAction {
  type: typeof UPDATE_SERVER_AUTHENTICATED;
  payload: {
    serverAuthenticated: boolean;
  };
  meta?: Meta;
}

interface UpdateTestHeadersAction {
  type: typeof UPDATE_TEST_HEADERS;
  payload: {
    testHeaders: TestHeaders;
  };
}

interface UpdateTestTagsAction {
  type: typeof UPDATE_TEST_TAGS;
  payload: {
    testTags?: TestTags;
  };
}

interface SetSeekerIdAction {
  type: typeof SET_SEEKER_ID;
  payload: {
    id: number;
  };
}

export type UserAction =
  | UpdateAuthenticatedAction
  | UpdateServerAuthenticatedAction
  | SetSeekerIdAction
  | UpdateTestHeadersAction
  | UpdateTestTagsAction;
