import querystring from 'query-string';
import { v4 as uuid } from 'uuid';

import type {
  GetLoggedOutRecommendationsQueryVariables,
  MutationAddSalaryNudgeFeedbackArgs,
} from '../../graphql/graphql.ts';

import { mockedBanners } from './fixtures/banners';
import { mockLoggedOutRecommendations } from './fixtures/getLoggedOutRecommendations.ts';
import { mockMarqueeCompanies } from './fixtures/getMarqueeCompanies';
import { mockRecommendations } from './fixtures/getRecommendations.ts';
import { mockSavedJobs } from './fixtures/getSavedJobs';
import savedSearchesErrorMessage from './fixtures/saved-search-error-messages.json';
import { jobDetails } from './mock/jobDetails/jobDetails';
import { savedSearches } from './mock/savedSearches';
import { defaultCandidateResolver } from './mock-overrides.ts';
import delay from './utils/delay-response';

const maxNumberExceededResponse = {
  __typename: 'CreateApacSavedSearchFailure',
  error: {
    __typename: 'CreateApacSavedSearchErrorMaxExceeded',
    message: savedSearchesErrorMessage['max-number-exceeded'],
  },
};

const invalidLocationResponse = {
  __typename: 'CreateApacSavedSearchFailure',
  error: {
    __typename: 'CreateApacSavedSearchInvalidLocationError',
    message: savedSearchesErrorMessage['invalid-location'],
  },
};

const duplicateResponse = {
  __typename: 'CreateApacSavedSearchFailure',
  error: {
    __typename: 'CreateApacSavedSearchDuplicateError',
    message: savedSearchesErrorMessage.duplicate,
  },
};

const badRequestResponse = {
  __typename: 'CreateApacSavedSearchFailure',
  error: {
    message: 'Bad request',
  },
};

const unregisteredMaxNumberExceededResponse = {
  __typename: 'CreateUnregisteredApacSavedSearchWithChecksumFailure',
  error: {
    __typename: 'CreateUnregisteredApacSavedSearchErrorMaxExceeded',
    message: savedSearchesErrorMessage['max-number-exceeded'],
  },
};

const unregisteredInvalidLocationResponse = {
  __typename: 'CreateUnregisteredApacSavedSearchWithChecksumFailure',
  error: {
    __typename: 'CreateUnregisteredApacSavedSearchInvalidLocationError',
    message: savedSearchesErrorMessage['invalid-location'],
  },
};

const unregisteredDuplicateResponse = {
  __typename: 'CreateUnregisteredApacSavedSearchWithChecksumFailure',
  error: {
    __typename: 'CreateUnregisteredApacSavedSearchDuplicateError',
    message: savedSearchesErrorMessage.duplicate,
  },
};

const unregisteredInvalidEmailResponse = {
  __typename: 'CreateUnregisteredApacSavedSearchWithChecksumFailure',
  error: {
    __typename: 'CreateUnregisteredApacSavedSearchInvalidEmailError',
    message: savedSearchesErrorMessage['invalid-email'],
  },
};

const unregisteredInvalidChecksumResponse = {
  __typename: 'CreateUnregisteredApacSavedSearchWithChecksumFailure',
  error: {
    __typename: 'CreateUnregisteredApacSavedSearchInvalidChecksumError',
    message: savedSearchesErrorMessage['generic-error'],
  },
};

const resolveTestData = () => {
  const { testData = '' } =
    typeof window !== 'undefined'
      ? querystring.parseUrl(window.location.href).query
      : {};
  return testData;
};

const isSearchSaved = (searchQueryString: string) => {
  const searchQueries = savedSearches.map(
    (savedSearch) => savedSearch.query.searchQueryString,
  );
  return searchQueries.includes(searchQueryString);
};

const resolverOverrides = (_mockStore: any) => ({
  CreateSavedJobPayload: {
    __resolveType: (arg0: any) => arg0.__typename,
  },
  DeleteSavedJobPayload: {
    __resolveType: (arg0: any) => arg0.__typename,
  },
  TrackJobDetailsViewedPayload: {
    __resolveType: (arg0: any) => arg0.__typename,
  },
  TrackSearchResultSelectedPayload: {
    __resolveType: (arg0: any) => arg0.__typename,
  },
  CreateApacSavedSearchPayload: {
    __resolveType: (arg0: any) => arg0.__typename,
  },
  CreateApacSavedSearchError: {
    __resolveType: (arg0: any) => arg0.__typename,
  },
  CreateUnregisteredApacSavedSearchWithChecksumPayload: {
    __resolveType: (arg0: any) => arg0.__typename,
  },
  CreateUnregisteredApacSavedSearchError: {
    __resolveType: (arg0: any) => arg0.__typename,
  },
  KeywordSuggestionType: {
    __resolveType: () => 'KeywordSuggestion',
  },
  LocationSuggestionType: {
    __resolveType: (arg0: any) => arg0.__typename,
  },
  Recommendations: {
    recommendedGlobalJobs: (_: any, args: any) => {
      const testData = resolveTestData();

      if (testData === 'recommendationsError') {
        return new Error('ERROR_WITH_RECOMMENDATIONS_RESOLVER');
      }

      return mockRecommendations(
        testData === 'noRecommendations' ? 0 : args.limit,
      ).viewer?.recommendations?.recommendedGlobalJobs;
    },
  },
  Candidate: {
    apacSavedSearches: () => savedSearches,
    searchSavedJobs: (_: any, __: any) => [
      {
        __typename: 'SearchSavedJobPartial',
        jobId: '29663971',
      },
      {
        __typename: 'SearchSavedJobPartial',
        jobId: '29663948',
      },
    ],
    searchAppliedJobs: (_: any, __: any) => [
      {
        __typename: 'SearchAppliedJobPartial',
        jobId: '29677777',
      },
    ],
    savedJobs: (_: any, __: any) => {
      const testData = resolveTestData();

      if (testData === 'savedJobsError') {
        return new Error('ERROR_WITH_CANDIDATE_SAVEDJOBS_RESOLVER');
      }

      const edges =
        testData === 'savedJobsEmpty'
          ? []
          : mockSavedJobs.viewer?.savedJobs?.edges;
      const total =
        testData === 'savedJobsEmpty'
          ? 0
          : mockSavedJobs.viewer?.savedJobs?.total;

      return {
        __typename: mockSavedJobs.__typename,
        edges,
        total,
      };
    },
    salaryNudge: (_: any, __: any) => ({
      __typename: 'CandidateSalaryNudge',
      firstName: 'Joe',
      id: 'f0213b64-e6ae-4e01-8677-0df5b7974ase',
      imageUrl: 'https://seekcdn.com/candidate/nudge/salary-illustration.svg',
      prompt: 'Setting a salary in your profile helps employers find you.',
      text: "The average salary of jobs you're interested in is $230,000",
      callToActions: [
        {
          __typename: 'CandidateSalaryNudgeCallToAction',
          displayText: 'Set expected salary as 230K',
          operation: 'Yes',
        },
      ],
    }),
  },
  IJobProfileSalaryMatch: {
    __resolveType: (arg0: any) => arg0.__typename,
  },
  TopApplicantBadge: {
    __resolveType: (arg0: any) => arg0.__typename,
  },
  JobDisplayTag: {
    __resolveType: (arg0: any) => arg0.__typename,
  },
  JobSalary: {
    label: ({ label: salaryLabel }: { label: string }) => salaryLabel,
    currencyLabel: ({ currencyLabel }: { currencyLabel: string | null }) =>
      currencyLabel,
  },
  Query: {
    viewer: () => {
      const testData = resolveTestData();
      if (testData === 'viewerError') {
        return new Error('ERROR_WITH_VIEWER_RESOLVER');
      }

      return defaultCandidateResolver;
    },
    jobDetails: (_: any, { id }: any) => jobDetails({ id }),
    marqueeCompanies: () => mockMarqueeCompanies,
    banner: () => mockedBanners,
    searchKeywordsSuggest: (_: any, { query }: any) => {
      const words = [
        'chef',
        'chemist warehouse',
        'chemist',
        'chef cook',
        'chemistry',
      ];

      const filteredWords = words.filter(
        (w) => w.indexOf(query.toLowerCase()) >= 0,
      );

      const suggestions = filteredWords.map((w) => ({
        text: w,
        highlights: [
          {
            start: w.indexOf(query.toLowerCase()),
            end: query.length + w.indexOf(query.toLowerCase()),
          },
        ],
        __typename: 'KeywordSuggestion',
      }));

      return {
        suggestions,
        metadata: {
          __typename: 'SearchSuggestionsMetadata',
          requestToken: 'some-token',
          flightId: 'some-flight-id',
        },
        __typename: 'SearchKeywordSuggestions',
      };
    },
    searchLocationsSuggest: (_: any, { query, isRemoteEnabled }: any) => {
      const remoteSuggestionGroup = {
        __typename: 'LocationSuggestionGroup',
        label: 'Remote',
        suggestions: [
          {
            text: 'Work from home',
            highlights: [],
          },
        ],
      };

      const recentSuggestionGroup = {
        __typename: 'LocationSuggestionGroup',
        label: 'Recent',
        suggestions: [
          {
            text: 'All Melbourne VIC',
            highlights: [],
          },
        ],
      };

      const relatedSuggestionGroup = {
        __typename: 'LocationSuggestionGroup',
        label: 'Related areas',
        suggestions: [
          {
            text: 'Melbourne CBD & Inner Suburbs Melbourne VIC',
            highlights: [],
          },
          {
            text: 'Bayside & South Eastern Suburbs Melbourne VIC',
            highlights: [],
          },
          {
            text: 'Eastern Suburbs Melbourne VIC',
            highlights: [],
          },
          {
            text: 'Northern Suburbs Melbourne VIC',
            highlights: [],
          },
          {
            text: 'Western Suburbs Melbourne VIC',
            highlights: [],
          },
        ],
      };

      if (!query) {
        if (isRemoteEnabled) {
          return {
            suggestions: [recentSuggestionGroup, relatedSuggestionGroup],
          };
        }
        return {
          suggestions: [
            remoteSuggestionGroup,
            recentSuggestionGroup,
            relatedSuggestionGroup,
          ],
        };
      }

      const locationsSuggestions = [
        'Melbourne VIC 3000',
        'Melbourne VIC 3004',
        'East Melbourne VIC 3002',
        'West Melbourne VIC 3003',
      ];

      const filteredLocationsSuggestions = locationsSuggestions.filter(
        (location) => location.toLowerCase().indexOf(query.toLowerCase()) >= 0,
      );

      const suggestions = filteredLocationsSuggestions.map((location) => ({
        text: location,
        highlights: [
          {
            start: location.toLowerCase().indexOf(query.toLowerCase()),
            end:
              query.length +
              location.toLowerCase().indexOf(query.toLowerCase()),
          },
        ],
        __typename: 'LocationSuggestion',
      }));

      return {
        suggestions,
      };
    },
    loggedOutRecommendedJobs: (
      _: any,
      { jobId }: GetLoggedOutRecommendationsQueryVariables,
    ) => {
      const jobIdAsInt = parseInt(jobId, 10);

      // Simulate number of jobs returned based on jobId
      if (jobIdAsInt >= 0 && jobIdAsInt < 5) {
        return {
          ...mockLoggedOutRecommendations.loggedOutRecommendedJobs,
          globalJobs:
            mockLoggedOutRecommendations.loggedOutRecommendedJobs?.globalJobs?.slice(
              0,
              jobIdAsInt,
            ),
        };
      } else if (jobId === 'error') {
        throw new Error('error!');
      }

      return mockLoggedOutRecommendations.loggedOutRecommendedJobs;
    },
  },
  Mutation: {
    addSalaryNudgeFeedback: (
      _: any,
      { nudgeId }: MutationAddSalaryNudgeFeedbackArgs,
    ) => {
      const testData = resolveTestData();

      if (testData === 'addSalaryNudgeFeedbackError') {
        return new Error('AddSalaryNudgeFeedback GraphQL Error');
      }

      return {
        __typename: 'CandidateSalaryNudgeFeedbackPayload',
        nudgeId,
      };
    },
    reportJob: (_: any, args: any) => {
      const success = args?.input?.comment !== 'FAILURE';

      return {
        __typename: 'ReportJobPayload',
        success,
      };
    },
    createSavedJob2: (_: any, args: any) => ({
      __typename: 'CreateSavedJobSuccess',
      id: args?.input?.id,
    }),
    deleteSavedJob2: (_: any, args: any) => ({
      __typename: 'DeleteSavedJobSuccess',
      id: args?.input?.id,
    }),
    createApacSavedSearch: async (_: any, args: any) => {
      const { searchQueryString } = args.input;
      const { where, keywords } =
        querystring.parseUrl(`?${searchQueryString}`).query || {};

      if (where === 'INVALID_LOCATION') {
        return invalidLocationResponse;
      }
      if (keywords === 'MAX_NUMBER_EXCEEDED') {
        return maxNumberExceededResponse;
      }
      if (keywords === 'BAD_REQUEST') {
        return badRequestResponse;
      }
      if (isSearchSaved(args.input.searchQueryString)) {
        return duplicateResponse;
      }

      const res = {
        __typename: 'CreateApacSavedSearchSuccess',
        savedSearch: {
          __typename: 'ApacSavedSearch',
          id: uuid(),
          name: 'Software Engineer Roles in Melbourne',
          countLabel: '13',
          countryCode: 'AU',
          createdDate: {
            shortLabel: '23h ago',
            __typename: 'SavedSearchDateTime',
          },
          subscribeToNewJobs: true,
          query: {
            __typename: 'SavedSearchApacQuery',
            searchQueryString,
            parameters: [],
          },
        },
      };

      savedSearches.unshift(res.savedSearch);

      await delay();

      return res;
    },
    createUnregisteredApacSavedSearchWithChecksum: (_: any, args: any) => {
      const { searchQueryString: inputSearchQueryString } = args.input;
      const { where, keywords } =
        querystring.parseUrl(`?${inputSearchQueryString}`).query || {};

      if (where === 'INVALID_LOCATION') {
        return unregisteredInvalidLocationResponse;
      }
      if (keywords === 'MAX_NUMBER_EXCEEDED') {
        return unregisteredMaxNumberExceededResponse;
      }
      if (keywords === 'INVALID_EMAIL') {
        return unregisteredInvalidEmailResponse;
      }
      if (keywords === 'INVALID_CHECKSUM') {
        return unregisteredInvalidChecksumResponse;
      }
      if (keywords === 'DUPLICATE') {
        return unregisteredDuplicateResponse;
      }
      return {
        __typename: 'CreateUnregisteredApacSavedSearchWithChecksumSuccess',
        id: '12345',
      };
    },
    trackJobDetailsViewed: () => ({
      __typename: 'TrackJobDetailsViewedSuccess',
      eventId: '12345',
    }),
    trackSearchResultSelected: () => ({
      __typename: 'TrackSearchResultSelectedSuccess',
      eventId: '12345',
    }),
  },
});

export default resolverOverrides;
