import { Box, Text, IconSearch, Column, Columns } from 'braid-design-system';
import { motion } from 'motion/react';

import { useAnimationDuration } from '../../hooks/useAnimationDuration';
import { useMinSearchBarText } from '../../hooks/useMinSearchBarText';
import { SearchRefinementBar } from '../SearchRefinementBar/SearchRefinementBar';

export const MinimisedSearchBar = ({
  handleOnClickMinSearchBarPlaceholder,
}: {
  handleOnClickMinSearchBarPlaceholder: () => void;
}) => {
  const minSearchBarText = useMinSearchBarText();
  const animationTransition = useAnimationDuration();
  const isSearchBarMinimised = true;

  return (
    <motion.div key="main2" layoutId="search-main">
      <Columns
        space={{
          mobile: 'gutter',
          tablet: 'xsmall',
        }}
        alignY="center"
        collapseBelow="tablet"
      >
        <Column width="1/3">
          <motion.div
            key="keyword2"
            layoutId="searchbar-keyword"
            initial={{
              opacity: 0,
              borderRadius: 5,
            }}
            animate={{
              opacity: 1,
              borderRadius: 5,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{ duration: animationTransition }}
          >
            <Box
              marginY="small"
              paddingX="small"
              paddingY="xxsmall"
              position="relative"
              background="surface"
              borderRadius="full"
              width="full"
              onClick={handleOnClickMinSearchBarPlaceholder}
              cursor="pointer"
            >
              <Text baseline={false} maxLines={1} icon={<IconSearch />}>
                {minSearchBarText}
              </Text>
            </Box>
          </motion.div>
        </Column>
        <Column>
          <motion.div
            layoutId="searchpills2"
            layout="position"
            key="pill2"
            transition={{ duration: animationTransition }}
            initial={{
              opacity: 1,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
          >
            <Box marginY="small">
              <SearchRefinementBar
                closeFilterOnScroll={true}
                isSearchBarMinimised={isSearchBarMinimised}
              />
            </Box>
          </motion.div>
        </Column>
      </Columns>
    </motion.div>
  );
};
