import { Box, IconSearch, Stack, Text } from 'braid-design-system';
import type { DebouncedFuncLeading } from 'lodash';
import { motion } from 'motion/react';
import { useEffect, useRef, useState } from 'react';

import { useSelector } from 'src/store/react';
import type { whereSuggestionSelected } from 'src/store/search';
import {
  selectIsHomePage,
  selectIsSrp,
  selectKeywordField,
} from 'src/store/selectors';

import { ANIMATION_DURATION, HANDLE_SCROLL_TIMEOUT } from '../../constants';
import { useMinSearchBarText } from '../../hooks/useMinSearchBarText';
import { ExpandedSearchBar } from '../ExpandedSearchBar/ExpandedSearchBar';
import { SearchRefinementBar } from '../SearchRefinementBar/SearchRefinementBar';

import { MobileKeywordField } from './components/MobileKeywordField';
import { MobileWhereField } from './components/MobileWhereField';

export const MobileSearchBar = ({
  onWhereSuggestionSelected,
  onWhereInputBlur,
  debouncedSearch,
}: {
  onWhereSuggestionSelected: (
    payload: Parameters<typeof whereSuggestionSelected>[0],
  ) => void;
  onWhereInputBlur: () => void;
  debouncedSearch: DebouncedFuncLeading<(nextPathName?: string) => void>;
}) => {
  const isHomePage = useSelector(selectIsHomePage);
  const isSRP = useSelector(selectIsSrp);

  const [isExpandedView, setIsExpandedView] = useState(false);
  const [disableExpandedViewTrigger, setDisableExpandedViewTrigger] =
    useState(false);

  const targetRef = useRef<HTMLElement | null>(null);

  const handleOnClickMinSearchBarPlaceholder = () => {
    setDisableExpandedViewTrigger(true);
    setIsExpandedView(true);

    setTimeout(() => {
      setDisableExpandedViewTrigger(false);
    }, HANDLE_SCROLL_TIMEOUT);
  };

  useEffect(() => {
    setIsExpandedView(false);
  }, [isSRP]);

  useEffect(() => {
    if (disableExpandedViewTrigger) return;

    const handleScroll = () => {
      if (isExpandedView) {
        setIsExpandedView(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const keywordsField = useSelector(selectKeywordField);

  const minSearchBarText = useMinSearchBarText();

  return (
    <>
      {isHomePage && (
        <ExpandedSearchBar
          onWhereSuggestionSelected={onWhereSuggestionSelected}
          onWhereInputBlur={onWhereInputBlur}
          debouncedSearch={debouncedSearch}
        />
      )}

      {isSRP && (
        <>
          {!isExpandedView && (
            <>
              <motion.div
                layout="position"
                layoutId="mobileSearchBar"
                key="mobile-searchBar"
                transition={{
                  duration: ANIMATION_DURATION,
                  ease: 'linear',
                }}
                initial={{
                  opacity: 1,
                }}
                animate={{
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                }}
              >
                <Stack space="xsmall">
                  <Box
                    ref={targetRef}
                    paddingX="small"
                    style={{
                      paddingLeft: '14px',
                    }}
                    paddingY="xsmall"
                    position="relative"
                    background="surface"
                    borderRadius="standard"
                    width="full"
                    onClick={handleOnClickMinSearchBarPlaceholder}
                    cursor="pointer"
                  >
                    <Text baseline={false} maxLines={1}>
                      <IconSearch />
                      <Box component="span" paddingLeft="xsmall">
                        {isExpandedView ? keywordsField : minSearchBarText}
                      </Box>
                    </Text>
                  </Box>

                  <motion.div
                    layout="position"
                    layoutId="mobileWhereField"
                    key="mobile-whereField"
                    transition={{
                      duration: ANIMATION_DURATION,
                      ease: 'linear',
                    }}
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 0,
                      height: 0,
                    }}
                    exit={{
                      opacity: 0,
                    }}
                    style={{
                      overflow: 'visible',
                      position: 'relative',
                    }}
                  >
                    <Box
                      style={{
                        position: 'absolute',
                        width: '100%',
                      }}
                    >
                      <MobileWhereField
                        onSuggestionSelected={onWhereSuggestionSelected}
                        onWhereInputBlur={onWhereInputBlur}
                        onClear={debouncedSearch}
                      />
                    </Box>
                  </motion.div>
                  <motion.div
                    layout="position"
                    layoutId="mobileRefineBar"
                    key="mobile-refineBar"
                    transition={{
                      duration: ANIMATION_DURATION,
                      ease: 'linear',
                    }}
                    initial={{
                      opacity: 1,
                    }}
                    animate={{
                      opacity: 1,
                      marginTop: '-12px',
                    }}
                    exit={{
                      opacity: 0,
                    }}
                  >
                    <SearchRefinementBar />
                  </motion.div>
                </Stack>
              </motion.div>
            </>
          )}

          {isExpandedView && (
            <>
              <motion.div
                layout="position"
                layoutId="mobileSearchBar"
                key="mobile-searchBar2"
                transition={{
                  duration: ANIMATION_DURATION,
                  ease: 'linear',
                }}
                initial={{
                  opacity: 1,
                }}
                animate={{
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                }}
              >
                <Stack space="xsmall">
                  <MobileKeywordField
                    onSuggestionSelected={debouncedSearch}
                    onInputBlur={debouncedSearch}
                    onClear={debouncedSearch}
                  />

                  <motion.div
                    layout="position"
                    layoutId="mobileWhereField"
                    key="mobile-whereField2"
                    transition={{
                      duration: ANIMATION_DURATION,
                      ease: 'linear',
                    }}
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                    }}
                    exit={{
                      opacity: 0,
                    }}
                  >
                    <MobileWhereField
                      onSuggestionSelected={onWhereSuggestionSelected}
                      onWhereInputBlur={onWhereInputBlur}
                      onClear={debouncedSearch}
                    />
                  </motion.div>
                  <motion.div
                    layout="position"
                    layoutId="mobileRefineBar"
                    key="mobile-refineBar2"
                    transition={{
                      duration: ANIMATION_DURATION,
                      ease: 'linear',
                    }}
                    initial={{
                      opacity: 1,
                    }}
                    animate={{
                      opacity: 1,
                    }}
                    exit={{
                      opacity: 0,
                    }}
                  >
                    <SearchRefinementBar />
                  </motion.div>
                </Stack>
              </motion.div>
            </>
          )}
        </>
      )}
    </>
  );
};
