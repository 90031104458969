import type { ExperimentConfig } from '@seek/candidate-feature-flag';

// It's advisable to define each experiment in a separate const and then add it to
// the experimentsToFetch array.
// This is to make it easier to use the experiments hooks later in components
// 
// For example:
//  const experimentObject = {
//    key: 'experiment-key',
//    trackingNumber: 1,
//  };
//  const experimentsToFetch = [experimentObject, ...];
// 
// Then in the component:
//  const { value } = useExperimentValue(experimentObject.key)
//  if (value === 'variant') { ... } else { ... }

/**
 * Add all experiments for all pages to this array.
 * This is what is being used to call Feature Flags Query (LaunchDarkly)
 */
export const experimentsToFetch: ExperimentConfig[] = [];
