import { useEffect } from 'react';

export const useHotjarEvent = (event: string) => {
  useEffect(() => {
    window.hj ||= function () {
      // eslint-disable-next-line prefer-rest-params
      (hj.q = hj.q || []).push(arguments);
    };

    window.hj('event', event);
  }, [event]);
};
