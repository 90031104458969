import { useTranslations } from '@vocab/react';
import { Button, Column, Columns, Stack } from 'braid-design-system';
import type { DebouncedFuncLeading } from 'lodash';
import { motion } from 'motion/react';

import { useSelector } from 'src/store/react';
import type { whereSuggestionSelected } from 'src/store/search';
import { selectIsSrp } from 'src/store/selectors';

import KeywordField from '../../../SearchBar/KeywordField/KeywordField';
import WhereField from '../../../SearchBar/WhereField/WhereField';
import { useAnimationDuration } from '../../hooks/useAnimationDuration';
import { SearchRefinementBar } from '../SearchRefinementBar/SearchRefinementBar';

import translations from './.vocab';

export const ExpandedSearchBar = ({
  onWhereSuggestionSelected,
  onWhereInputBlur,
  debouncedSearch,
}: {
  onWhereSuggestionSelected: (
    payload: Parameters<typeof whereSuggestionSelected>[0],
  ) => void;
  onWhereInputBlur: () => void;
  debouncedSearch: DebouncedFuncLeading<(nextPathName?: string) => void>;
}) => {
  const { t } = useTranslations(translations);
  const animationTransition = useAnimationDuration();
  const isSRP = useSelector(selectIsSrp);

  return (
    <motion.div key="main" layoutId="search-main">
      <Stack
        space={{
          mobile: true ? 'xsmall' : 'none',
          tablet: 'xxsmall',
        }}
      >
        <Columns
          space={{
            mobile: 'gutter',
            tablet: 'xxsmall',
          }}
          alignY="bottom"
          collapseBelow="tablet"
        >
          <Column width="3/5">
            <motion.div
              key="keyword"
              layoutId="searchbar-keyword"
              initial={{
                opacity: 0,
                borderRadius: 5,
              }}
              animate={{
                opacity: 1,
                borderRadius: 5,
              }}
              exit={{
                opacity: 0,
              }}
              transition={{ duration: animationTransition }}
            >
              <KeywordField
                onSuggestionSelected={debouncedSearch}
                onInputBlur={debouncedSearch}
                onClear={debouncedSearch}
              />
            </motion.div>
          </Column>
          <Column>
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              transition={{ duration: animationTransition }}
            >
              <WhereField
                onSuggestionSelected={onWhereSuggestionSelected}
                onInputBlur={onWhereInputBlur}
                onClear={debouncedSearch}
              />
            </motion.div>
          </Column>
          <Column width="content">
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              transition={{ duration: animationTransition }}
            >
              <Button
                id="searchButton"
                tone="brandAccent"
                type="submit"
                data={{
                  automation: 'searchButton',
                }}
                aria-label={t('Submit search')}
              >
                {t('SEEK')}
              </Button>
            </motion.div>
          </Column>
        </Columns>
        <motion.div
          layoutId="searchpills2"
          layout="position"
          key="pills"
          transition={{ duration: animationTransition }}
          initial={{
            opacity: 1,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          <SearchRefinementBar closeFilterOnScroll={isSRP} />
        </motion.div>
      </Stack>
    </motion.div>
  );
};
