import { Box, Text, useResponsiveValue } from 'braid-design-system';
import { useRef } from 'react';

import { useHotjarEvent } from 'src/hooks/useHotjarEvent';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade/react.ts';
import { useDispatch, useSelector } from 'src/store/react.ts';
import {
  selectIsResultsLoading,
  selectPills,
  selectSolMetadata,
} from 'src/store/results/selectors';
import { updateKeywordsField } from 'src/store/search/index.ts';
import { selectDynamicSearchBarConfiguration } from 'src/store/search/selectors';
import { useRunSearch } from 'src/store/search/useRunSearch';

import { Pills } from './components/Pills/Pills';

import * as styles from './DynamicPillV2.css';

export const DynamicPillV2 = () => {
  const solMetadata = useSelector(selectSolMetadata);
  const dispatch = useDispatch();
  const pills = useSelector(selectPills);
  const ref = useRef<HTMLDivElement>(null);
  const isLoading = useSelector(selectIsResultsLoading);
  const analyticsFacade = useAnalyticsFacade();
  const runSearch = useRunSearch();
  const isMobile =
    useResponsiveValue()({
      mobile: true,
      tablet: false,
    }) || false;

  const dynamicSearchBarConfiguration = useSelector(
    selectDynamicSearchBarConfiguration,
  );
  const handleOnPillClick = (
    label: string,
    keyword: string,
    position: number,
    isActive: boolean,
  ) => {
    analyticsFacade.refinementSuggestionClicked({
      solMetadata,
      refinedKeyword: {
        isActive,
        label,
        position,
      },
      searchBarConfiguration: dynamicSearchBarConfiguration,
    });

    dispatch(updateKeywordsField(keyword));
    dispatch(runSearch({ pathname: '/jobs' }));
  };

  useHotjarEvent('dynamicPillsV2');

  const hasPills = pills && pills?.length > 0;

  if (!hasPills) return null;

  if (isLoading) {
    return (
      <Box
        data={{ automation: 'dynamicPillsPlaceHolder' }}
        paddingY="xxsmall"
        opacity={0}
      >
        <Text
          baseline={false}
          maxLines={1}
          size={isMobile ? 'small' : 'standard'}
        >
          Placeholder
        </Text>
      </Box>
    );
  }

  return (
    <Box
      data={{ automation: 'dynamicPills' }}
      display="flex"
      position="relative"
      alignItems="center"
      className={{
        [styles.dotSeparator]: true,
        [styles.bleedPillsAcrossBorder]: isMobile,
      }}
    >
      <Pills handleOnPillClick={handleOnPillClick} pills={pills} ref={ref} />
    </Box>
  );
};
