import * as http from 'http';
import * as https from 'https';

import HttpClient, {
  isTimeoutError as isHttpClientTimeoutError,
  isConnectionReset,
  isGatewayTimeout,
} from '@seek/ca-http-client';
import { metrics } from '@seek/metrics-js';
import type { AxiosError } from 'axios';
import { parse } from 'cookie';
import isEmpty from 'lodash/isEmpty';
import isError from 'lodash/isError';
import merge from 'lodash/merge';

import { environment } from 'src/config';

import { findRootCause } from './error';

const LAST_KNOWN_SOL_USER_ID_KEY = 'last-known-sol-user-id';

const httpClientMiddleware = ({
  requestConfig,
  response = {},
  duration,
}: {
  requestConfig: Record<string, any>;
  response: Record<string, any>;
  duration: number;
}) => {
  const { label } = requestConfig;
  const { config = requestConfig, status } = response;
  const { method = 'get', url } = config;

  metrics.httpRequestTiming({
    method,
    status,
    duration,
    isUpstream: true,
    endpointName: label || (url.indexOf('token') > -1 ? 'auth-token' : ''),
  });
};

export const isTimeoutError = (error: AxiosError) => {
  if (!isError(error)) {
    return false;
  }

  const rootCause = findRootCause(error);
  return isHttpClientTimeoutError(rootCause);
};

const commonHttpClientConfig = {
  appName: 'Chalice',
  metrics: httpClientMiddleware,
  retryPolicy: {
    isRetriableError: (err: AxiosError) =>
      isTimeoutError(err) || isConnectionReset(err) || isGatewayTimeout(err),
  },
  defaultRequestConfig: {
    httpAgent: new http.Agent({ keepAlive: true }),
    httpsAgent: new https.Agent({ keepAlive: true }),
  },
};

const getMockedOptions = () => {
  const useMockedAuth =
    (environment === 'development' && window.location.protocol === 'http:') ||
    environment === 'dev' ||
    environment === 'dark-prod';

  return {
    useMockedAuth,
    mockOptions:
      useMockedAuth && window
        ? {
            authenticated: parse(document.cookie).AUTH_TOKEN !== undefined,
          }
        : undefined,
  };
};

export type HttpClientConfig = Partial<
  ConstructorParameters<typeof HttpClient>[0]
>;

export const createAuthenticatedHttpClient = (
  config: HttpClientConfig = {},
) => {
  // support mocked and unmocked auth scenarios locally
  const { useMockedAuth, mockOptions } = getMockedOptions();

  return new HttpClient({
    authOptions: {
      useMockedAuth,
      ...(useMockedAuth ? { mockOptions } : {}),
      forceStrategy: 'AUTH0',
    },
    ...merge({}, commonHttpClientConfig, config),
  });
};

export const createUnauthenticatedHttpClient = (
  config: HttpClientConfig = {},
) => new HttpClient(merge({}, commonHttpClientConfig, config));

type HeaderType = keyof typeof headerMapper;
const headerMapper = {
  userAgent: 'X-Seek-Forwarded-User-Agent',
  xRealIp: 'X-Real-IP',
  ja3Hash: 'Seek-Ja3-Hash',
  ja4: 'Seek-Ja4',
  threatScore: 'Seek-Threat-Score',
  verifiedBot: 'Seek-Verified-Bot',
  botScore: 'Seek-Bot-Score',
  ipCountry: 'Seek-Ip-Country',
};

export const withServerHeaders = (
  serverHeaders: Record<string, string | undefined>,
) =>
  Object.entries(serverHeaders).reduce<Record<string, string | undefined>>(
    (acc, [key, value]) => {
      const mappedHeader = headerMapper[key as HeaderType];
      if (ENV.CLIENT || isEmpty(value) || !mappedHeader) {
        return acc;
      }
      acc[mappedHeader] = value;
      return acc;
    },
    {},
  );

export const withRequestId = (id?: string) => {
  if (!id) {
    return {};
  }

  return { 'X-Request-Id': id };
};

export const withLastKnownSolUserId = (lastKnownSolUserId?: string) => {
  if (!ENV.SERVER || isEmpty(lastKnownSolUserId)) {
    return {};
  }

  return { Cookie: `${LAST_KNOWN_SOL_USER_ID_KEY}=${lastKnownSolUserId}` };
};

export const withDevAuth = (authToken?: string) => {
  const useDevAuth =
    environment === 'development' ||
    environment === 'dev' ||
    environment === 'dark-prod';
  if (authToken && useDevAuth) {
    return { Authorization: authToken };
  }

  return {};
};
