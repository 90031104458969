import striptags from 'striptags';

import type { SafetyHelmetProps } from 'src/components/SafetyHelmet/SafetyHelmet';
import { useBrandName } from 'src/hooks/useBrandName';

import { generateMetaDescription, generateMetaTitle } from '../utils';

import { useJobDetailsHeadTitle } from './title';

interface JobDetailsHeadProps {
  abstract: string;
  normalisedRoleTitle: string;
  broaderLocationName: string;
  normalisedOrganisationName: string;
}

export const useJobDetailsHead = ({
  abstract,
  normalisedRoleTitle,
  broaderLocationName,
  normalisedOrganisationName,
}: JobDetailsHeadProps): SafetyHelmetProps => {
  const brand = useBrandName();
  const title = useJobDetailsHeadTitle({
    brand,
    normalisedRoleTitle,
    broaderLocationName,
    normalisedOrganisationName,
  });

  return {
    title,
    meta: [
      ...generateMetaTitle(title),
      ...generateMetaDescription(striptags(abstract)),
    ],
  };
};
