import { useIsExperimentsReady } from '@seek/candidate-feature-flag';
import { useEffect } from 'react';

import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';

export const useHomeTracking = () => {
  const analyticsFacade = useAnalyticsFacade();
  const isExperimentsReady = useIsExperimentsReady();

  useEffect(() => {
    if (isExperimentsReady) {
      analyticsFacade.homePageLoaded({
        flightId: undefined,
      });
    }
  }, [analyticsFacade, isExperimentsReady]);
};
