import type { SalaryType } from '@seek/chalice-types';
import { useTranslations } from '@vocab/react';
import { useCallback, useMemo, useState } from 'react';

import { getRefineSearchQuery } from 'src/components/Search/utils/utils';
import { useSearchSalaryRanges } from 'src/hooks/useSearchSalaryRanges';
import { useSelector } from 'src/store/react';
import { selectSearchQuery } from 'src/store/selectors';

import translations from './.vocab';
import { SalaryRange } from './SalaryRange';

export const useSalaryRangeField = (
  handleSelection: (fieldId: string) => void,
) => {
  const { t } = useTranslations(translations);
  const [activeSalary, setActiveSalary] = useState<string | null>(null);

  const handleSalarySelection = useCallback(
    (value: string) => {
      setActiveSalary(value);
      handleSelection('SalaryRange');
    },
    [handleSelection],
  );

  const searchQuery = getRefineSearchQuery(useSelector(selectSearchQuery));

  const { searchSalaryRanges: data } = useSearchSalaryRanges();

  const defaultFrequency = data.find(
    (item) => item.frequency.isDefault,
  )?.frequency;

  const frequency =
    searchQuery.salarytype ?? (defaultFrequency?.type as SalaryType);

  const selectedSalaryFrequencyRanges = data.find(
    ({ frequency: { type } }) => type === frequency,
  )?.range;

  const [selectedSalaryRangeFromValue, selectedSalaryRangeToValue] =
    searchQuery.salaryrange?.split('-') ?? [];

  const selectedSalaryRangeFromIndex =
    selectedSalaryFrequencyRanges?.findIndex(
      ({ value }) => value === selectedSalaryRangeFromValue,
    ) ?? -1;

  const defaultSalaryRangeFrom = selectedSalaryFrequencyRanges?.[0];

  const defaultSalaryRangeTo =
    selectedSalaryFrequencyRanges?.[selectedSalaryFrequencyRanges.length - 1];

  const activeSalaryRangeFromValue =
    selectedSalaryRangeFromValue > '0' ? selectedSalaryRangeFromValue : null;

  const activeSalaryRangeToValue =
    selectedSalaryRangeToValue > '0' ? selectedSalaryRangeToValue : null;

  const fromLabel =
    selectedSalaryFrequencyRanges?.[selectedSalaryRangeFromIndex]?.longLabel ??
    defaultSalaryRangeFrom?.longLabel;

  const selectedSalaryRangeToIndex =
    selectedSalaryFrequencyRanges?.findIndex(
      ({ value }) => value === selectedSalaryRangeToValue,
    ) ?? -1;

  const toLabel =
    selectedSalaryFrequencyRanges?.[selectedSalaryRangeToIndex]?.longLabel ??
    defaultSalaryRangeTo?.longLabel;

  const displayLabel = useMemo(() => {
    if (activeSalary) {
      if (activeSalaryRangeFromValue && activeSalaryRangeToValue) {
        return `${fromLabel} - ${toLabel}`;
      } else if (activeSalaryRangeFromValue) {
        return `From ${fromLabel}`;
      } else if (activeSalaryRangeToValue) {
        return `Up to ${toLabel}`;
      }
    }

    return t('Pay');
  }, [
    activeSalaryRangeFromValue,
    activeSalaryRangeToValue,
    fromLabel,
    toLabel,
    activeSalary,
    t,
  ]);

  // Filter out any falsy values & get the count of selected values
  const totalSelected = [
    activeSalaryRangeFromValue,
    activeSalaryRangeToValue,
  ].filter(Boolean).length;

  return {
    id: 'SalaryRange',
    ariaLabel: t('salary range'),
    summarySentence: undefined,
    label: displayLabel,
    filterComponent: () => (
      <SalaryRange handleSelection={handleSalarySelection} />
    ),
    isSelected:
      (Boolean(activeSalary) && Boolean(activeSalaryRangeFromValue)) ||
      Boolean(activeSalaryRangeToValue),
    totalSelected,
  };
};
