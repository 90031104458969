import {
  useExperimentsEligibleProps,
  useIsExperimentsReady,
  type FeatureFlagProviderProps,
} from '@seek/candidate-feature-flag';
import { useEffect, useMemo } from 'react';

import { appName, version } from 'src/config';
import { experimentsToFetch } from 'src/config/experiments';
import { featureFlagsToFetch } from 'src/config/featureFlags';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { getUserAgent } from 'src/modules/chalice-user-agent/isomorphic-useragent';
import { useSelector } from 'src/store/react';
import { selectSolId } from 'src/store/selectors';

export const useFeatureFlagProviderProps = (): Omit<
  FeatureFlagProviderProps,
  'children'
> => {
  const solId = useSelector(selectSolId) || '';

  return useMemo(
    () => ({
      featureFlags: featureFlagsToFetch,
      userAgent: getUserAgent(),
      solId,
      experimentsKeysAndTrackingNumbers: experimentsToFetch,
      customContext: {
        applicationContext: {
          key: solId,
          namespace: appName,
          build: version,
          version,
        },
      },
    }),
    [solId],
  );
};

export const InitialiseExperiments = () => {
  const analyticsFacade = useAnalyticsFacade();
  const {
    tealiumEligibleProps: { experimentsEligible, hubbleExperiments },
  } = useExperimentsEligibleProps();
  const isExperimentsReady = useIsExperimentsReady();

  useEffect(() => {
    if (isExperimentsReady) {
      analyticsFacade.setExperiments({
        experimentsEligible,
        hubbleExperiments,
      });
    }
  }, [
    analyticsFacade,
    experimentsEligible,
    hubbleExperiments,
    isExperimentsReady,
  ]);

  return null;
};
